<template>
  <div class="px-4">
    <div class="pt-4 title">
      <h3>Reset Password</h3>
    </div>

    <!-- send email link -->
    <template v-if="!layouts.actives.checkEmail">
      <div class="f-s-16 mt-2">
        Enter your email and we will send you a password reset link.
      </div>
      <v-form ref="form" @submit.prevent>
        <v-text-field
          v-model="dataSources.current.email"
          label="Enter Email"
          :rules="dataSources.current.emailRule"
        ></v-text-field>
      </v-form>
      <web-btn class="mt-4 width-full" @click="clickContinue">
        Continue
      </web-btn>

      <div class="d-flex align-center my-8">
        <v-divider></v-divider>
        <span class="px-4">Or</span>
        <v-divider></v-divider>
      </div>

      <div class="text-center">
        Already have an account?
        <a class="f-w-600" @click="$router.push('/sign-in')">Sign In</a>
      </div>
    </template>

    <!-- text -->
    <div v-else class="mt-15 f-s-16">
      Check your email for a link to reset your password.
      <span class="text-red">
        If it doesn't appear within a few minutes, check your spam folder.
      </span>
      <br/>
      <div class="f-s-16 text-center">
        <v-menu offset-y min-width="200" :disabled="dataSources.current.countdown > 0" :key="dataSources.current.countdown">
          <template v-slot:activator="{ on, attrs }">
            <span class="text-decoration-underline" v-bind="attrs" v-on="on" :style="dataSources.current.countdown > 0 ? 'color: grey' : ''">Didn't receive the email?</span>
            <span class="text-red" v-if="dataSources.current.countdown > 0"> ({{
                dataSources.current.countdown
              }}s)</span>
          </template>
          <div class="menu-box">
            <div class="menu-box-triangle"></div>
            <v-layout class="menu-item-content">
              <v-flex class="menu-item" @click="clickResend">
                <v-icon size="40" color="blue" class="mr-2">mdi-reload</v-icon>
                <div class="field-label">Resend Email</div>
              </v-flex>
            </v-layout>
          </div>
        </v-menu>

      </div>
    </div>
  </div>
</template>

<script>
import WebBtn from '../../components/base/WebBtn.vue'
import { DataType, Popup, Tool } from '@/assets/js/util'
import { AccountApi } from '@/api'

export default {
  components: { WebBtn },
  data () {
    return {
      assists: {
        tool: new Tool(),
        popup: new Popup()
      },
      timer: null,
      dataSources: {
        current: {
          email: '',
          countdown: 300,
          emailRule: [v => DataType.ValidationRules.EMAIL.test(v) || 'Must be a valid email.']
        }
      },
      layouts: {
        actives: {
          checkEmail: false
        }
      }
    }
  },
  created () {
    this.countDown(this.layouts.actives.checkEmail)
  },
  methods: {
    countDown (val) {
      if (val) {
        this.dataSources.current.countdown = 300
        this.timer = setInterval(() => {
          if (this.dataSources.current.countdown > 0) {
            this.dataSources.current.countdown--
          } else {
            clearInterval(this.timer)
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    clickContinue () {
      this.$refs.form.validate()
      if (this.$refs.form.validate()) {
        this.sendEmail()
      }
    },
    sendEmail () {
      const email = this.dataSources.current.email
      AccountApi.sendForgotPasswordEmail({
        portfolioId: this.$store.getters.getPortfolioId,
        email
      }, () => {
        this.layouts.actives.checkEmail = true
        this.countDown(this.layouts.actives.checkEmail)
      }, (err) => {
        this.assists.popup.popupForError(err.message)
      })
    },
    clickResend () {
      this.sendEmail()
    }
  }
}
</script>

<style lang='sass' scoped>
.title
  height: 18vh

.menu-box
  height: 70px
  position: relative

  &-triangle
    width: 20px
    height: 20px
    border: 20px solid
    border-color: transparent transparent #eae9e9 transparent
    position: absolute
    left: 43%
    top: -40px

  .menu-item-content
    background-color: #eae9e9
    width: 100%
    height: 100%
    border-radius: 5px
    margin-top: 20px

  .menu-item
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    width: 50%

    .field-label
      font-weight: 500
      font-size: 16px

.v-menu__content
  box-shadow: 0 5px 5px -5px rgb(0 0 0 / 20%), 0 8px 10px 0 rgb(0 0 0 / 0%), 0 3px 0 0 rgb(0 0 0 / 0%)
</style>
